/* Type */

@font-face {
  font-family: 'Hyper';
  src: url('../fonts/Hyper.otf');
}
@font-face {
    font-family: GothamNarrow-Bold;
    src: url('../fonts/GothamNarrow-Bold.otf');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: GothamNarrow-Medium;
    src: url('../fonts/GothamNarrow-Medium.otf');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: GothamNarrow-Book;
    src: url('../fonts/GothamNarrow-Book.otf');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: GothamNarrow-BookItalic;
    src: url('../fonts/GothamNarrow-BookItalic.otf');
    font-weight: 400;
    font-style: normal
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #0a0a0a;
  overflow-x: hidden;
  backface-visibility: hidden;
  user-select: text;
}

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "GothamNarrow-Medium";
}

button:focus {
  outline: none;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.loading {
  border-radius: 50%;
  width: 35px;
  margin: auto;
  height: 35px;
  display: flex;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

::selection {
  background: rgb(39, 83, 253); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgb(39, 83, 253); /* Gecko Browsers */
}

input[disabled], textarea[disabled],
select[disabled='disabled']{
   -webkit-text-fill-color: rgba(255, 255, 255, 1);
   -webkit-opacity: 1;
   color: rgba(255, 255, 255, 1);
}
